import { first } from "remeda";
import type { HostPoolWithType } from "../../pools/query.ts";

export function getPoolsForLocation<TPool extends HostPoolWithType>(pools: TPool[], locationSlug: string): TPool[] {
    return pools.filter((p) => p.location === locationSlug);
}

export function getDefaultDesignatedPoolForLocation(pools: HostPoolWithType[], locationSlug: string) {
    const available = getPoolsForLocation(pools, locationSlug);
    return available.find((p) => p.is_default_designated) ?? first(available);
}
